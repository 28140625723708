<template>
    <div class="foundation-entry" :class="[entry ? 'foundation-entry-' + entry.type : '', entry && entry.theme.pageLayout ? 'page-layout-'+entry.theme.pageLayout : '']">
        <component v-if="!notFound && entry && !entry.theme.noHeader" :is="'page-header-'+entry.theme.pageLayout" :entry="entry" :class="{'bg-themeColor': hasThemeColors, 'bg-black': !hasThemeColors && !entry.theme.noHeader}"></component>
        <div class="foundation entry-content" :class="entry ? 'foundation-' + entry.slug : ''" ref="entryContent">
            <div v-if="entry" class="content-blocks">

                <div v-if="entry.leadText" class="entry-leadText block block-text-standard block-text-lead container-fluid" :class="{'bg-themeColor': hasThemeColors, 'bg-black': !hasThemeColors && !entry.theme.noHeader}">
                    <div class="row content-block-row">
                        <div class="col-12 col-md-9 mx-md-auto mr-lg-0 col-lg-6 offset-lg-3 text-lead" v-html="entry.leadText" />
                    </div>
                </div>
                
                <div v-if="entry.credits" class="block entry-credits block-text-standard container-fluid">
                    <div class="row content-block-row">
                        <div class="col">
                            <div v-if="entry.credits" class="credits" v-html="entry.credits"></div>
                        </div>
                    </div>
                </div>
                <component v-for="(element, index) in entry.contentBlocks" :key="'element-' + index" :is="element.type" :class="element.backgroundColor ? element.backgroundColor : ''" :block="element"></component>
            </div>
            <NotFound v-if="notFound" />
            <Spinner v-if="!entry && !notFound" />
        </div>
    </div>
</template>

<script>
import { i18n } from '../i18n';
/* eslint-disable vue/no-unused-components */
// @ is an alias to /src

import { Page } from '@/http/Page';
import Spinner from '@/components/Spinner';
import accordeon from '@/components/contentBlocks/Accordeon';
import imageSingle from '@/components/contentBlocks/ImageSingle';
import textStandard from '@/components/contentBlocks/TextStandard';
import text2Cols from '@/components/contentBlocks/Text2Cols';
import videoNative from '@/components/contentBlocks/VideoNative';
import audioNative from '@/components/contentBlocks/AudioNative';
import team from '@/components/contentBlocks/Team';
import newsletter from '@/components/contentBlocks/Newsletter';
import linksProjectJournalEntries from '@/components/contentBlocks/LinksProjectJournalEntries';
import map from '@/components/contentBlocks/Map';
import projectList from '@/components/contentBlocks/ProjectList';
import featuredProjects from '@/components/contentBlocks/FeaturedProjects';
import callToActionEntries from '@/components/contentBlocks/CallToActionEntries';
import coachingEntries from '@/components/contentBlocks/CoachingEntries';
import NotFound from '@/components/NotFound';
import quote from '@/components/contentBlocks/Quote';
import relatedJournalTagCategories from '@/components/contentBlocks/RelatedJournalTagCategories';
import infoDropdown from '@/components/contentBlocks/InfoDropdown';
import buttonLinks from '@/components/contentBlocks/ButtonLinks';
import linkAnchor from '@/components/contentBlocks/LinkAnchor';
import mediaTeaser from '@/components/contentBlocks/MediaTeaser';

// New Page Headers
//import HeaderPage from '@/components/page/HeaderPage';
import PageHeaderDefault from '@/components/page/PageHeaderDefault';
import PageHeaderMultimediaHeader from '@/components/page/PageHeaderMultimediaHeader';
import PageHeaderMultimediaHeaderTwoCols from '@/components/page/PageHeaderMultimediaHeaderTwoCols';

import InternalLinkReplacementMixin from '@/mixins/InternalLinkReplacementMixin';

export default {
    name: 'Foundation',
    components: {
        Spinner,
        videoNative,
        audioNative,
        textStandard,
        accordeon,
        map,
        imageSingle,
        text2Cols,
        team,
        linksProjectJournalEntries,
        projectList,
        featuredProjects,
        callToActionEntries,
        coachingEntries,
        newsletter,
        NotFound,
        quote,
        relatedJournalTagCategories,
        infoDropdown,
        buttonLinks,
        linkAnchor,
        mediaTeaser,
        PageHeaderDefault,
        PageHeaderMultimediaHeaderTwoCols,
        PageHeaderMultimediaHeader,
    },
    mixins: [InternalLinkReplacementMixin],
    data() {
        return {
            entry: null,
            notFound: false,
            linksInited: false
        };
    },
    computed: {
        menu() {
            return this.$store.getters['navigation/get'];
        },
        globalSiteData() {
            return this.$store.getters['general/get'];
        },
        activeMainNavItem() {
            if (this.menu) {
                const activeMainNavItemArr = this.menu.filter((navItems) => navItems.active);
                return activeMainNavItemArr.length ? activeMainNavItemArr[0] : null;
            }
            return null;
        },
        hasThemeColors() {
            return this.entry && (this.entry.theme.primaryColor || this.entry.theme.primaryColor);
        }
    },
    updated() {},
    mounted() {
        this.getPage(this.$route.params.pathArr); // new
    },
    unmounted() {
        this.resetPageContent();
    },
    activated() {
        if (this.entry && this.entry.metaData) {
            this.initPage();
            setTimeout(this.gotoScroll, 10);
        }
    },
    deactivated() {
        this.resetPageContent();
    },
    methods: {
        async getPage(pathArr) {
            try {
                var response = await Page.get(pathArr);
                this.entry = response.data;
                this.initPage();
                this.$nextTick(() => {
                    setTimeout(this.gotoScroll, 10);
                    if (!this.linksInited) {
                        this.initInternalLinks(this.$refs.entryContent);
                        this.linksInited = true;
                    }
                });
            } catch {
                this.notFound = true;
                this.initNotFoundPage();
            }
        },
        initPage() {
            let pageClass = '';
            if (this.entry.theme && this.entry.theme.noHeader || false) {
                this.$store.commit('view/setPageClass', 'no-page-header');
            } else if (this.hasThemeColors) {
                this.$el.classList.add('theme-custom-colors');
                this.$store.commit('view/setPageClass', 'theme-custom-colors');
                this.$store.commit('view/setPageCssVars', this.entry.theme);
            }
            
            this.$setHeaderData(this.entry.metaData);
            this.$store.commit('navigation/setPageRoot', this.entry.parent);
            if (this.entry && this.entry.languageLinks) {
                this.$store.commit('navigation/setLangNav', this.entry.languageLinks);
            }
        },
        initNotFoundPage() {
            this.$setHeaderData({
                title: '404 - Seite nicht gefunden',
                description: '',
                image: null,
                noIndex: true
            });
            this.$store.commit('navigation/setPageRoot', {
                title: '404',
                path: null
            });
            const availableLanguageLinks = [
                { 'lang': 'de', 'path': '/', 'autoTranslated': false },
                { 'lang': 'fr', 'path': '/fr/', 'autoTranslated': true },
                { 'lang': 'en', 'path': '/en/', 'autoTranslated': true }
            ];
            this.$store.commit('navigation/setLangNav', availableLanguageLinks.sort((a, b) => a.lang == i18n.global.locale ? -1 : 1));
        },
        resetPageContent() {
            if (this.hasThemeColors) {
                this.$el.classList.remove('theme-custom-colors');
            }
        },
        gotoScroll() {
            if (window.location.hash) {
                const anchorEl = document.getElementById(window.location.hash.split('#')[1]);
                if (anchorEl) {
                    const offsetEl = document.getElementById('site-header-navbar');
                    const scrollOffset = anchorEl.getBoundingClientRect().top + (window.scrollY || window.pageYOffset) - (offsetEl ? offsetEl.clientHeight : 0);
                     window.scroll({
                        top: scrollOffset, 
                        left: 0, 
                        behavior: 'auto' 
                    });
                }
            }
        },
    },
};
</script>